(function () {
  'use strict';

  class Classrooms {
    constructor(ClassroomsRest, $cookies, $rootScope) {
      this.ClassroomsRest = ClassroomsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    classroomsIndex(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.classrooms().get(classroom,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    classroomsCreate(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.classrooms().save({classroom: classroom},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myClassrooms(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.myClassrooms().get(classroom,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    classroomCreate(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.classroom().save(classroom,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    classroomView(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.classroom().get(classroom,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    results(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.results().get(classroom,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    createModuleSessions(module_session = {}, callback = angular.noop) {
      return this.ClassroomsRest.module_sessions().save({module_session: module_session},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    deleteModuleSession(module_session = {}, callback = angular.noop) {
      return this.ClassroomsRest.module_session().remove(module_session,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    completeModuleSessions(module_session = {}, callback = angular.noop) {
      return this.ClassroomsRest.module_sessions_complete().save({module_session: module_session},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    getModuleSession(module_session = {}, callback = angular.noop) {
      return this.ClassroomsRest.module_sessions().get(module_session,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    classroomDelete(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.classroom().remove(classroom,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    classroomEdit(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.classroom().update({classroom: classroom},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    lomMetadata(lomMetadata = {}, callback = angular.noop) {
      return this.ClassroomsRest.lomMetadata().get(lomMetadata,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    metrics(metrics = {}, callback = angular.noop) {
      metrics.metrics = 'metrics';
      return this.ClassroomsRest.classroom().get(metrics,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    export(classroom = {}, callback = angular.noop) {
      return this.ClassroomsRest.export().save(classroom,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Classrooms
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Classrooms', Classrooms);
}());
